import {IdentityBasketUserKind} from '@lavka-birds/b2b-common';
import loadable from '@loadable/component';
import React from 'react';
import {useSelector} from 'react-redux';
import {useLocation} from 'react-router';
import {Redirect, Route, Switch} from 'react-router-dom';

import {PAGE_PATHS} from '@/src/constants/routes';
import {useI18n} from '@/src/i18n';
import {generatePathUsersList, generatePathUserView} from '@/src/lib/generate-path';
import {useCompanySlugStrict} from 'client/hooks/use-company-slug-strict';
import {SelectCompanyPage} from 'client/pages/select-company-page';
import {setYupLocale} from 'client/scenes/app/utils/setYupLocale';
import {Notifications} from 'client/scenes/notifications';
import {UsersImportController} from 'client/scenes/users/users-import-controller/users-import-controller';
import {selectPermissions, selectUser} from 'client/store/app';
import {PageFallback} from 'client/ui/page-fallback';
import {RedirectWithStatus} from 'client/ui/redirect-with-status';
import {HttpCode} from 'types/http';

const UserViewPage = loadable(
    () =>
        import(
            /* webpackChunkName: 'user-view-page-chunk' */
            'client/pages/users-pages/user-view-page'
        ),
    {fallback: <PageFallback />}
);

const UsersListPage = loadable(
    () =>
        import(
            /* webpackChunkName: 'users-list-page-chunk' */
            'client/pages/users-pages/users-list-page'
        ),
    {fallback: <PageFallback />}
);

const RolesPage = loadable(
    () =>
        import(
            /* webpackChunkName: 'roles-page-chunk' */
            'client/pages/users-pages/roles-page'
        ),
    {fallback: <PageFallback />}
);

const PolicyEditPage = loadable(
    () =>
        import(
            /* webpackChunkName: 'policy-edit-page-chunk' */
            'client/pages/roles-pages/policy-edit-page'
        ),
    {fallback: <PageFallback />}
);

const RoleEditPage = loadable(
    () =>
        import(
            /* webpackChunkName: 'role-edit-page-chunk' */
            'client/pages/roles-pages/role-edit-page'
        ),
    {fallback: <PageFallback />}
);

const RoleDetailPage = loadable(
    () =>
        import(
            /* webpackChunkName: 'role-detail-page-chunk' */
            'client/pages/roles-pages/role-detail-page'
        ),
    {fallback: <PageFallback />}
);

const UsersImportPreviewPage = loadable(
    () =>
        import(
            /* webpackChunkName: 'users-import-preview-page-chunk' */
            'client/pages/users-pages/users-import-preview-page'
        ),
    {fallback: <PageFallback />}
);

const DraftsPage = loadable(
    () =>
        import(
            /* webpackChunkName: 'drafts-page-chunk' */
            'client/pages/draft-pages/drafts-page'
        ),
    {fallback: <PageFallback />}
);

const DraftPolicyPage = loadable(
    () =>
        import(
            /* webpackChunkName: 'draft-policy-page-chunk' */
            'client/pages/draft-pages/draft-policy-page'
        ),
    {fallback: <PageFallback />}
);

const NotFoundPage = loadable(
    () =>
        import(
            /* webpackChunkName: 'not-found-page-chunk' */
            'client/pages/not-found-page'
        ),
    {fallback: <PageFallback />}
);

const QueryErrorPage = loadable(
    () =>
        import(
            /* webpackChunkName: 'query-error-page-chunk' */
            'client/pages/query-error-page'
        ),
    {fallback: <PageFallback />}
);

const BaseCompanyRoute: React.FC = () => {
    const user = useSelector(selectUser);
    const companySlug = useCompanySlugStrict();
    const permissions = useSelector(selectPermissions);
    const canReadUsers = permissions.canReadUsers();

    const isGlobalUserKind = user?.kind === IdentityBasketUserKind.GLOBAL;
    const redirectToUserViewPage = !canReadUsers && !isGlobalUserKind && user;

    return (
        <RedirectWithStatus
            path={PAGE_PATHS.PARTNER_OR_COMPANY_BASE}
            exact
            to={
                redirectToUserViewPage
                    ? generatePathUserView({partnerOrCompanyId: companySlug, userId: user?.id})
                    : generatePathUsersList({partnerOrCompanyId: companySlug})
            }
            status={HttpCode.MovedPermanently}
        />
    );
};

export const App: React.FC = () => {
    const i18n = useI18n();
    setYupLocale(i18n);

    const user = useSelector(selectUser);
    const {search} = useLocation();

    return (
        <>
            <Switch>
                <Redirect path={PAGE_PATHS.SELECT_PARTNER} exact to={`${PAGE_PATHS.SELECT_COMPANY}${search}`} />
                <Route path={PAGE_PATHS.SELECT_COMPANY} exact component={SelectCompanyPage} />
                <Route path={PAGE_PATHS.USERS_BASE}>
                    {user ? <UsersImportController /> : null}
                    <Switch>
                        <Route path={PAGE_PATHS.USERS_LIST} exact component={UsersListPage} />
                        <Route path={PAGE_PATHS.USER_ROLES} component={RolesPage} />
                        <Route path={PAGE_PATHS.USER_IMPORT_PREVIEW} exact component={UsersImportPreviewPage} />
                        <Route path={PAGE_PATHS.ROLE_CREATE} exact component={RoleEditPage} />
                        <Route path={PAGE_PATHS.ROLE_EDIT} exact component={RoleEditPage} />
                        <Route path={PAGE_PATHS.ROLE_DETAIL} exact component={RoleDetailPage} />
                        {/* Не exact, т.к. внутри страницы есть разделение на вкладки "Общее" и "Роли" */}
                        <Route path={PAGE_PATHS.USER_VIEW} component={UserViewPage} />
                    </Switch>
                </Route>
                <Route path={PAGE_PATHS.POLICIES_BASE}>
                    <Switch>
                        <Route path={PAGE_PATHS.POLICY_VIEW} exact component={PolicyEditPage} />
                    </Switch>
                </Route>
                <Route path={PAGE_PATHS.DRAFTS_BASE}>
                    <Switch>
                        <Route path={PAGE_PATHS.DRAFT_POLICIES_LIST} exact component={DraftsPage} />
                        <Route path={PAGE_PATHS.DRAFT_POLICY_CREATE} exact component={DraftPolicyPage} />
                        <Route path={PAGE_PATHS.DRAFT_POLICY_EDIT} exact component={DraftPolicyPage} />
                        <Route component={DraftsPage} />
                    </Switch>
                </Route>
                <RedirectWithStatus
                    path={PAGE_PATHS.ROOT}
                    exact
                    to={PAGE_PATHS.SELECT_COMPANY}
                    status={HttpCode.MovedPermanently}
                />
                <Route path={PAGE_PATHS.QUERY_ERROR} exact component={QueryErrorPage} />
                <Route path={PAGE_PATHS.NOT_FOUND}>
                    <NotFoundPage />
                </Route>
                <BaseCompanyRoute />
            </Switch>
            <Notifications />
        </>
    );
};
