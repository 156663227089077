import {generatePath} from 'react-router';

import {PAGE_PATHS, RolesTab} from 'constants/routes';
import type {CompanyPathParams} from 'types/path';

export function generatePathCompanyBase({partnerOrCompanyId}: CompanyPathParams) {
    return generatePath(PAGE_PATHS.PARTNER_OR_COMPANY_BASE, {partnerOrCompanyId});
}

export function generatePathUsersList({partnerOrCompanyId}: CompanyPathParams) {
    return generatePath(PAGE_PATHS.USERS_LIST, {partnerOrCompanyId});
}

export function generatePathUserRolesList({partnerOrCompanyId}: CompanyPathParams) {
    return generatePath(PAGE_PATHS.USER_ROLES_LIST, {partnerOrCompanyId});
}

export function generatePathUserRolesEvents({partnerOrCompanyId}: CompanyPathParams) {
    return generatePath(PAGE_PATHS.USER_ROLES_EVENTS, {partnerOrCompanyId});
}

export function generatePathUserRolesPolicies({partnerOrCompanyId}: CompanyPathParams) {
    return generatePath(PAGE_PATHS.USER_ROLES_POLICIES, {partnerOrCompanyId});
}

export function generatePathUserRolesRequest({partnerOrCompanyId}: CompanyPathParams) {
    return generatePath(PAGE_PATHS.USER_ROLES_REQUESTS, {partnerOrCompanyId});
}

export function generatePathUserRolesRequestView({partnerOrCompanyId, roleId}: CompanyPathParams & {roleId: number}) {
    return generatePath(PAGE_PATHS.USER_ROLES_REQUESTS_VIEW, {partnerOrCompanyId, roleId});
}

export function generatePathUserView({partnerOrCompanyId, userId}: CompanyPathParams & {userId: string}) {
    return generatePath(PAGE_PATHS.USER_VIEW, {partnerOrCompanyId, userId});
}

export function generatePathUserViewRoles({partnerOrCompanyId, userId}: CompanyPathParams & {userId: string}) {
    return generatePath(PAGE_PATHS.USER_VIEW_ROLES, {partnerOrCompanyId, userId});
}

export function generatePathUserViewRoleView({
    partnerOrCompanyId,
    userId,
    roleId
}: CompanyPathParams & {userId: string; roleId: string}) {
    return generatePath(PAGE_PATHS.USER_VIEW_ROLES_VIEWS, {partnerOrCompanyId, userId, roleId});
}

export function generatePathUserImportPreview({partnerOrCompanyId}: CompanyPathParams) {
    return generatePath(PAGE_PATHS.USER_IMPORT_PREVIEW, {partnerOrCompanyId});
}

export function generatePathPolicyView({
    partnerOrCompanyId,
    policyId,
    policyTab
}: CompanyPathParams & {policyId: string; policyTab?: string}) {
    return generatePath(PAGE_PATHS.POLICY_VIEW, {partnerOrCompanyId, policyId, policyTab});
}

export function generatePathPoliciesListView({partnerOrCompanyId}: CompanyPathParams) {
    return generatePath(PAGE_PATHS.POLICIES_LIST, {rolesTab: RolesTab.POLICIES, partnerOrCompanyId});
}

export function generatePathLoginCookie({partnerOrCompanyId}: CompanyPathParams) {
    return generatePath(PAGE_PATHS.LOGIN_COOKIE, {partnerOrCompanyId});
}

export function generatePathLogout({partnerOrCompanyId}: CompanyPathParams) {
    return generatePath(PAGE_PATHS.LOGOUT, {partnerOrCompanyId});
}

export function generatePathCourier() {
    return generatePath(PAGE_PATHS.COURIER_SIGN_IN);
}

export function generatePathCreateRole({partnerOrCompanyId}: CompanyPathParams) {
    return generatePath(PAGE_PATHS.ROLE_CREATE, {partnerOrCompanyId});
}

export function generatePathEditRole({partnerOrCompanyId, roleId}: CompanyPathParams & {roleId: number}) {
    return generatePath(PAGE_PATHS.ROLE_EDIT, {partnerOrCompanyId, roleId});
}

export function generatePathDetailRole({
    partnerOrCompanyId,
    roleId,
    roleDetailTab
}: CompanyPathParams & {roleId: number; roleDetailTab?: string}) {
    return generatePath(PAGE_PATHS.ROLE_DETAIL, {partnerOrCompanyId, roleId, roleDetailTab});
}

export function generatePathDraftRequestsList({partnerOrCompanyId}: CompanyPathParams) {
    return generatePath(PAGE_PATHS.DRAFT_REQUESTS_LIST, {partnerOrCompanyId});
}

export function generatePathDraftPoliciesList({partnerOrCompanyId}: CompanyPathParams) {
    return generatePath(PAGE_PATHS.DRAFT_POLICIES_LIST, {partnerOrCompanyId});
}

export function generatePathDraftPolicy({partnerOrCompanyId, policyId}: CompanyPathParams & {policyId: string}) {
    return generatePath(PAGE_PATHS.DRAFT_POLICY_EDIT, {partnerOrCompanyId, policyId});
}

export function generatePathCreateDraftPolicy({partnerOrCompanyId}: CompanyPathParams) {
    return generatePath(PAGE_PATHS.DRAFT_POLICY_CREATE, {partnerOrCompanyId});
}
